<template>
     <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Today's Question"
          :value="statsData.todayQuestions"
         />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-forum"
          title="Today Answer"
          :value="statsData.todayAnswers"
        
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-calendar-month"
          title="Month Questions"
          :value="statsData.monthQuestions"
         />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-calendar-month"
          title="Month Answers"
          :value="statsData.monthAnswers"
         />
      </v-col>

      


     
    </v-row>
</template>


<script>
export default {

    data(){
        return{
            statsData: {}
        }
    },

    methods:{
        getDashboard(){
            this.$DashboardApi.getDashboard().then(data=>{
                console.log(data.data.data);
                this.statsData = data.data.data;
            }).catch(error=>{
                console.log(error);
            })
        }
    },

    mounted(){
        this.getDashboard();
    }
    
}
</script>


<style scoped>

</style>