<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
   <StatsWidgetComponent/>

   
  </v-container>
</template>

<script>
import StatsWidgetComponent from "./components/core/StatsWidgetComponent.vue";

  export default {
    name: 'DashboardDashboard',

    components:{
      StatsWidgetComponent
    },

    data () {
      return {
           headers: [
             {
               text:'Conversation Id',
               value: 'conversationId'
             },
          {
            text: 'Message Activity Id',
            align: 'start',
            value: 'messageActivityId',
          },
          { text: 'Question', value: 'question' },
        
        
        ],
     
      }
    },

    methods: {
   
    },

    mounted(){
      
      this.$socket.client.close();
    }
  }
</script>
